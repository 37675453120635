import type {VariantProps} from 'class-variance-authority';
import {cva} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

export const articleAuthorStyles = cva(
  ['text-base font-aktivgroteskextended leading-[18px] font-bold mb-2'],
  {
    variants: {
      blogPageType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'font-shopifysans [&_>div]:!text-legacy-gray-90 [&_a]:text-legacy-gray-90 [&_span]:text-legacy-gray-90',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-text',
      },
    },
  },
);

export const articleAuthorContainerStyles = cva(['gap-x-1'], {
  variants: {
    hasPrefixValue: {
      true: '',
      false: 'flex flex-wrap',
    },
  },
});

const articleAuthorTimeVariants = cva(['text-body-sm text-shade-50'], {
  variants: {
    blogPageType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'text-button-light-secondary-border-active',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'font-normal text-engineering-dark-accent',
    },
  },
});

export const articleAuthorTimeStyles = (
  variants: VariantProps<typeof articleAuthorTimeVariants>,
) => twMerge(articleAuthorTimeVariants(variants));

export const articleAuthorLinkStyles = cva('', {
  variants: {
    blogPageType: {
      [BLOG_PAGE_TYPE.BLOG]: 'font-bold text-legacy-gray-90',
      [BLOG_PAGE_TYPE.RETAIL]: 'font-bold text-legacy-gray-90',
      [BLOG_PAGE_TYPE.PARTNERS]: 'font-bold text-legacy-gray-90',
      [BLOG_PAGE_TYPE.ENTERPRISE]: 'no-underline text-section-dark-text',
      [BLOG_PAGE_TYPE.ENGINEERING]: 'font-bold text-engineering-dark-text',
    },
  },
});

export const authorLinkContainerStyles = cva(['flex gap-x-1'], {
  variants: {
    hasPrefixValue: {
      true: 'mt-3 mb-1',
      false: '',
    },
  },
});

export const sharingSectionTitleStyles = cva(
  'font-bold text-xs font-aktivgroteskextended uppercase mt-4 tablet-xl:mt-0 tablet-xl:text-right',
  {
    variants: {
      blogPageType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'text-engineering-dark-accent',
      },
    },
  },
);

const sharingSectionLinkVariants = cva(
  'block h-9 w-9 fill-black hover:fill-shade-7',
  {
    variants: {
      blogPageType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: 'fill-[#E7ECFB]',
      },
    },
  },
);

export const sharingSectionLinkStyles = (
  variants: VariantProps<typeof sharingSectionLinkVariants>,
) => twMerge(sharingSectionLinkVariants(variants));
