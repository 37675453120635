import {Trans} from 'react-i18next';
import {Fragment} from 'react';

import {getAuthorUrl} from '@/components/blog/utils';
import {useTranslations} from '@/hooks/useTranslations';
import type {AuthorData, BLOG_PAGE_TYPE} from '@/components/blog/types';
import {twMerge} from '@/stylesheets/twMerge';

import {
  articleAuthorLinkStyles,
  articleAuthorTimeStyles,
  authorLinkContainerStyles,
  articleAuthorContainerStyles,
} from './style';
import {AuthorLink} from './AuthorLink';

interface BlogAuthorNameProps {
  ns: string;
  enBlogPath: string;
  authorData: AuthorData[];
  className?: string;
  modifiedAt?: string;
  blogPageType?: BLOG_PAGE_TYPE;
  timeToRead?: number | null;
}

export const BlogAuthorName = ({
  ns,
  className,
  enBlogPath,
  authorData,
  modifiedAt,
  blogPageType,
  timeToRead,
}: BlogAuthorNameProps) => {
  const {localizePath} = useTranslations();

  if (!authorData.length) {
    return null;
  }

  const [firstAuthor, ...allAuthors] = authorData;

  const itemProp = firstAuthor?.prefix?.includes('reviewed')
    ? 'reviewedBy'
    : 'author';
  const firstAuthorUrl = getAuthorUrl({
    localizedBlogPath: localizePath(enBlogPath),
    authorName: firstAuthor?.name,
  });

  const isFirstAuthorDisabled = firstAuthor?.isDisabledAuthor;
  const firstAuthorNameComponent = (
    <>
      {firstAuthor.prefix && <Trans i18nKey={firstAuthor.prefix} ns={ns} />}{' '}
      {isFirstAuthorDisabled ? (
        <Trans
          i18nKey="authorSection.byAuthor"
          values={{authorName: firstAuthor.name}}
          ns={ns}
          components={{
            wrapper: (
              <span
                itemProp={itemProp}
                itemScope
                itemType="https://schema.org/Person"
              />
            ),
            name: <span itemProp="name" />,
          }}
        />
      ) : (
        <Trans
          i18nKey="authorSection.byAuthor"
          values={{authorName: firstAuthor.name}}
          ns={ns}
          context={firstAuthorUrl && 'withLink'}
          components={{
            wrapper: (
              <span
                itemProp={itemProp}
                itemScope
                itemType="https://schema.org/Person"
              />
            ),
            url: (
              <a
                href={firstAuthorUrl}
                className="no-underline"
                itemProp="url"
              />
            ),
            name: <span itemProp="name" />,
          }}
        />
      )}
    </>
  );

  const timeToReadText = (
    <Trans
      i18nKey="authorSection.timeToRead"
      values={{minutes: timeToRead}}
      ns={ns}
    />
  );
  const timeToReadHtml = modifiedAt ? <> • {timeToReadText}</> : timeToReadText;

  return (
    <>
      <div
        className={twMerge(
          articleAuthorContainerStyles({
            hasPrefixValue: allAuthors.length > 0 && !!allAuthors[0].prefix,
          }),
          className,
        )}
      >
        {firstAuthorNameComponent}
        {allAuthors?.map((author, index) => (
          <Fragment key={`${author.slug || author.name}-${index}`}>
            {author.name && (
              <div
                className={authorLinkContainerStyles({
                  hasPrefixValue: !!author.prefix,
                })}
              >
                {!author.prefix && <span>{' / '}</span>}
                {author.prefix ? (
                  <AuthorLink author={author} ns={ns} enBlogPath={enBlogPath} />
                ) : (
                  <a
                    href={getAuthorUrl({
                      localizedBlogPath: localizePath(enBlogPath),
                      authorName: author?.name,
                    })}
                    className={articleAuthorLinkStyles({
                      blogPageType,
                    })}
                  >
                    <span>{`${author?.name}`}</span>
                  </a>
                )}
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {modifiedAt && (
        <time className={articleAuthorTimeStyles({blogPageType})}>
          {modifiedAt}
        </time>
      )}
      {Number(timeToRead) > 0 && (
        <span className={articleAuthorTimeStyles({blogPageType})}>
          {timeToReadHtml}
        </span>
      )}
    </>
  );
};
