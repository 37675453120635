import type {ReactNode} from 'react';

interface ImageWrapperProps {
  href?: string;
  children: ReactNode;
  className?: string;
  target?: string;
}

function ImageWrapper({href, className, target, children}: ImageWrapperProps) {
  if (href) {
    return (
      <a
        href={href}
        className={className}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}
        target={target}
      >
        {children}
      </a>
    );
  }

  return <div className={className}>{children}</div>;
}

export default ImageWrapper;
