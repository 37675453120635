import {cx} from 'class-variance-authority';

export const classNames = {
  tag: 'text-engineering-dark-text font-shopifysans font-normal hover:text-eyebrow-dark-text',
  title: cx(
    'text-engineering-dark-text font-aktivgroteskextended font-normal [&_h4]:font-normal',
  ),
  description: cx('text-engineering-dark-text font-shopifysans font-normal'),
  author: cx('text-engineering-dark-author-text font-shopifysans'),
  date: cx('text-engineering-dark-author-text font-shopifysans'),
  cardBorder: 'border-engineering-dark-accent',
};
