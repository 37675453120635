import {Trans} from 'react-i18next';

import {getAuthorUrl} from '@/components/blog/utils';
import {useTranslations} from '@/hooks/useTranslations';
import type {AuthorData} from '@/components/blog/types';

interface AuthorLinkProps {
  author: AuthorData;
  ns: string;
  enBlogPath: string;
}

export const AuthorLink = ({author, ns, enBlogPath}: AuthorLinkProps) => {
  const {localizePath} = useTranslations();

  const itemProp = author?.prefix?.includes('written') ? 'writtenBy' : 'author';
  const url = getAuthorUrl({
    localizedBlogPath: localizePath(enBlogPath),
    authorName: author?.name,
  });

  return (
    <>
      <Trans i18nKey={author.prefix} ns={ns} />{' '}
      <Trans
        i18nKey="authorSection.byAuthor"
        values={{authorName: author.name}}
        ns={ns}
        context={url && 'withLink'}
        components={{
          wrapper: (
            <span
              itemProp={itemProp}
              itemScope
              itemType="https://schema.org/Person"
            />
          ),
          url: <a href={url} className="no-underline" itemProp="url" />,
          name: <span itemProp="name" />,
        }}
      />
    </>
  );
};
