import {cx} from 'class-variance-authority';

const tagDarkStyles = cx(
  'text-eyebrow-dark-text font-faktum font-normal hover:text-eyebrow-dark-text',
);
const tagLightStyles = cx(
  'text-black font-faktum font-normal hover:text-black',
);

const textDarkStyles = cx('text-link-dark font-faktum font-normal');
const textLightStyles = cx('text-block font-faktum font-medium');

const dateDarkStyles = cx('text-[#A1A1AA] font-faktum');
const dateLightStyles = cx(
  'text-button-light-secondary-border-active font-shopifysans',
);

export const darkStyles = {
  tag: tagDarkStyles,
  title: textDarkStyles,
  description: textDarkStyles,
  author: textDarkStyles,
  date: dateDarkStyles,
};

export const lightStyles = {
  tag: tagLightStyles,
  title: textLightStyles,
  description: textLightStyles,
  author: textLightStyles,
  date: dateLightStyles,
};
