import {EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_HEADER_BANNER_NON_CORE_EN_BLOG} from '@/experiments';
import {useExperiment} from '@/hooks/useExperiment';

export enum EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE {
  Control = 'control',
  Treatment = 'treatment',
  Treatment_2 = 'treatment_2',
}

export const useBlogArticleWhatIsShopifyVideoTest = () => {
  const expValue = useExperiment(
    EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_HEADER_BANNER_NON_CORE_EN_BLOG,
  ) as EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE | undefined;

  return expValue;
};
