import compact from 'lodash/compact';

import type {ArticleMetafield} from '@/components/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE} from '@/hooks/experiments/useBlogArticleWhatIsShopifyVideoTest';

//TODO: update replace with authorName.replaceALL to replace all spaces to '+'
export function getAuthorLinkHrefForArticle(
  authorName: string,
  localizedBlogPath: string,
) {
  const searchPath = `${localizedBlogPath.replace(/\/$/, '')}/search`;

  return `${searchPath}?q=${authorName.replace(' ', '+')}`;
}

export interface GetAuthorUrlProps {
  localizedBlogPath: string;
  authorName?: string;
}

export const getAuthorUrl = ({
  localizedBlogPath,
  authorName,
}: GetAuthorUrlProps) => {
  if (!authorName) {
    return;
  }

  const formattedName = authorName.toLowerCase().replaceAll(' ', '-');

  return `${
    localizedBlogPath === '/' ? '' : localizedBlogPath
  }/authors/${formattedName}`;
};

const checkImagePromise = (path: string) =>
  new Promise<boolean>((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);

    img.src = path;
  });

export const checkImage = (path: string, cb: (isExist: boolean) => void) => {
  checkImagePromise(path).then((isExist) => {
    cb(isExist);
  });
};

export function socialSharerUrls(
  url: string,
  name: string,
  text: string,
  summary?: string,
) {
  const baseUrl = 'https://www.shopify.com/';
  const sharedUrl = encodeURIComponent(baseUrl + url);
  const title = text.replaceAll(' ', '+');

  switch (name) {
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${sharedUrl}`;
    case 'twitter':
      return `https://twitter.com/intent/tweet?text=${title}&url=${sharedUrl}&via=Shopify`;
    case 'linkedin':
      return `https://www.linkedin.com/shareArticle?mini=true&source=Shopify&title=${title}&url=${sharedUrl}`;
    case 'email':
      return `mailto:?subject=${title}&body=${title}%0A${sharedUrl}%0A%0A${summary}`;
  }
}

export function fetchReviewerName(metafields: ArticleMetafield[]) {
  const reviewerMetafield =
    metafields?.find((metafield) => metafield?.['key'] === 'reviewed_by')?.[
      'value'
    ] || '';

  return typeof reviewerMetafield === 'string' ? reviewerMetafield : '';
}

/**
 * Determines the path to the article page based on the blog handle.
 * @param blogHandle The handle of the blog.
 * @returns The path to the article page.
 * @example
 * determineArticlePath('blog') // '/blog'
 * determineArticlePath('partners') // '/partners/blog'
 * determineArticlePath('non-partners') // '/non-partners'
 */
export const determineArticlePath = (blogHandle: string) => {
  if (blogHandle === BLOG_PAGE_TYPE.ENTERPRISE) {
    return '/enterprise/blog';
  }

  if (blogHandle === BLOG_PAGE_TYPE.ENGINEERING) {
    return '/blog';
  }

  return `/${blogHandle}${blogHandle === 'partners' ? '/blog' : ''}`;
};

export const truncateByWords = (string: string, limit: number): string => {
  const metaDescriptionWords = string.split(' ').slice(0, limit);

  return metaDescriptionWords.join(' ');
};

export const getRightBannerButtonUrl = ({
  blogPageType,
  signupUrl,
  localizePath,
}: {
  blogPageType: string;
  signupUrl: string;
  localizePath: any;
}) => {
  switch (blogPageType) {
    case BLOG_PAGE_TYPE.ENTERPRISE:
      return localizePath('/solutions/retail/enterprise');
    case BLOG_PAGE_TYPE.RETAIL:
      return localizePath('pos?itcat=retailblog&itterm=seasonal_ad');
    default:
      return signupUrl;
  }
};

export const getInitials = (name: string) =>
  name
    .split(' ')
    .map((n) => n[0])
    .join('');

const READING_SPEED_WORDS_PER_MINUTE = 250;

export const getArticleTimeToRead = (content?: string) => {
  if (!content) {
    return 0;
  }

  const wordsCount = compact(content.split(' ')).length;

  return Math.ceil(wordsCount / READING_SPEED_WORDS_PER_MINUTE);
};

export const getPageCluster = (
  clusters: {cluster: string; [key: string]: string}[],
  clusterName: string,
) => clusters.find(({cluster}: {cluster: string}) => cluster === clusterName);

export const getShowLightButtonValue = (...conditions: boolean[]) =>
  conditions.reduce((acc, current) => acc || current, false);

export const getBlogArticleWhatIsShopifyVideoTest = (
  locale: string,
  expValue: EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE | undefined,
) => {
  if (
    [
      'en-HK',
      'en-ID',
      'en-IE',
      'en-IN',
      'en-MY',
      'en-NG',
      'en-NZ',
      'en-PH',
      'en-SG',
      'en-ZA',
    ].includes(locale)
  ) {
    return {
      showWhatIsShopifyExternalVideoButton:
        expValue === EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE.Treatment,
      showWhatIsShopifyModalVideoButton:
        expValue === EXP_WHAT_IS_SHOPIFY_VIDEO_BLOG_TYPE.Treatment_2,
    };
  }

  return {
    showWhatIsShopifyExternalVideoButton: false,
    showWhatIsShopifyModalVideoButton: false,
  };
};
