import {cva, cx} from 'class-variance-authority';

import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {
  darkStyles,
  lightStyles,
} from '@/pages/shopify.com/($locale)/enterprise/blog/styles/styles';
import {classNames as engineeringClassnames} from '@/pages/shopify.engineering/styles/styles';
import {twMerge} from '@/stylesheets/twMerge';

export const blogCardImageStyles = cva(
  [
    'object-cover w-full hover:scale-105 transition-transform duration-300 cursor-pointer',
  ],
  {
    variants: {
      type: {
        // NOTE: SEO uses "article__image--featured" class for analysis
        hero: 'article__image--featured mb-2',
        'grid-3': 'h-[202px] tablet:h-72 tablet-xl:h-[136px] desktop:h-[202px]',
        'grid-4': 'h-[148px]',
        noImage: 'hidden',
      },
    },
  },
);

export const blogCardTitleStyles = cva(
  [
    'tracking-[-.02em] font-normal pb-4 hover:underline font-aktivgroteskextended',
  ],
  {
    variants: {
      type: {
        hero: cx(
          'text-legacy-gray-90',
          'text-[28px] leading-9',
          'tablet:text-[32px] tablet:leading-10',
          'desktop:text-[40px] desktop:leading-[48px] desktop:tracking-[-.01em]',
        ),
        'grid-3': cx('text-shade-100', 'text-xl', 'tablet:text-2xl'),
        'grid-4': cx('text-shade-100', 'text-base'),
        noImage: cx('text-shade-100', 'text-base', 'desktop:text-xl'),
      },
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]: '',
        [BLOG_PAGE_TYPE.RETAIL]: '',
        [BLOG_PAGE_TYPE.PARTNERS]: '',
        [BLOG_PAGE_TYPE.ENTERPRISE]: '',
        [BLOG_PAGE_TYPE.ENGINEERING]: '',
      },
      mode: {
        light: '',
        dark: '',
      },
    },
    compoundVariants: [
      {
        blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
        mode: 'light',
        class: lightStyles.title,
      },
      {
        blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
        mode: 'dark',
        class: darkStyles.title,
      },
      {
        blogType: [BLOG_PAGE_TYPE.ENGINEERING],
        class: engineeringClassnames.title,
      },
      {
        blogType: [BLOG_PAGE_TYPE.ENGINEERING],
        class: twMerge(
          engineeringClassnames.title,
          'text-engineering-light-text',
        ),
        mode: 'light',
      },
    ],
  },
);

export const blogCardHorizontalTitleStyles = cx(
  'text-legacy-gray-90 font-medium pb-2 hover:underline font-aktivgroteskextended',
  'tracking-[-.02em]',
  'text-xl',
  'tablet:text-2xl',
);

export const eyebrowStyles = cx(
  'uppercase font-shopifysans font-medium pb-2',
  'text-[12px] leading-[20px] tracking-[0.02em]',
  'text-shade-100 hover:text-link-light-hover',
);

export const articleAuthorStyles = cva(
  ['font-shopifysans text-body-sm', 'font-bold mb-2'],
  {
    variants: {
      isMobileHidden: {
        true: 'hidden tablet:inline mr-2',
        false: '',
      },
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.RETAIL]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.PARTNERS]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'inline no-underline text-section-dark-text',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'text-engineering-dark-text hover:text-link-light-hover active:text-link-light-active',
      },
    },
  },
);

export const blogCardImageWrapperStyles = cva(['overflow-hidden'], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
  },
});

export const blogCardTagStyles = cva([''], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
    mode: {
      light: '',
      dark: '',
    },
  },
  compoundVariants: [
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'light',
      class: lightStyles.tag,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'dark',
      class: darkStyles.tag,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: engineeringClassnames.tag,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: twMerge(engineeringClassnames.tag, 'text-engineering-light-text'),
      mode: 'light',
    },
  ],
});

export const blogCardDescriptionStyles = cva([''], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
    mode: {
      light: '',
      dark: '',
    },
  },
  compoundVariants: [
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'light',
      class: lightStyles.description,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'dark',
      class: darkStyles.description,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: engineeringClassnames.description,
    },
  ],
});

export const blogCardDateStyles = cva([''], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
    mode: {
      light: '',
      dark: '',
    },
  },
  compoundVariants: [
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'light',
      class: lightStyles.date,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'dark',
      class: darkStyles.date,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: engineeringClassnames.date,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: twMerge(
        engineeringClassnames.date,
        'text-engineering-light-author-text',
      ),
      mode: 'light',
    },
  ],
});

export const blogCardAuthorStyles = cva(
  ['font-shopifysans text-body-sm', 'font-bold mb-2'],
  {
    variants: {
      isMobileHidden: {
        true: 'hidden tablet:inline mr-2',
        false: '',
      },
      blogType: {
        [BLOG_PAGE_TYPE.BLOG]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.RETAIL]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.PARTNERS]:
          'text-legacy-gray-90 hover:text-link-light-hover active:text-link-light-active',
        [BLOG_PAGE_TYPE.ENTERPRISE]:
          'inline no-underline text-section-dark-text',
        [BLOG_PAGE_TYPE.ENGINEERING]:
          'text-engineering-dark-text hover:text-link-light-hover active:text-link-light-active',
      },
      mode: {
        light: '',
        dark: '',
      },
    },
    compoundVariants: [
      {
        blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
        mode: 'light',
        class: lightStyles.author,
      },
      {
        blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
        mode: 'dark',
        class: darkStyles.author,
      },
      {
        blogType: [BLOG_PAGE_TYPE.ENGINEERING],
        class: engineeringClassnames.author,
      },
    ],
  },
);

export const blogCardHorizontalTextStyles = cva([''], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: '',
    },
    mode: {
      light: '',
      dark: '',
    },
  },
  compoundVariants: [
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'light',
      class: lightStyles.title,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENTERPRISE],
      mode: 'dark',
      class: darkStyles.title,
    },
    {
      blogType: [BLOG_PAGE_TYPE.ENGINEERING],
      class: engineeringClassnames.title,
    },
  ],
});

export const blogCardBorderStyles = cva(['border-shade-20'], {
  variants: {
    blogType: {
      [BLOG_PAGE_TYPE.BLOG]: '',
      [BLOG_PAGE_TYPE.RETAIL]: '',
      [BLOG_PAGE_TYPE.PARTNERS]: '',
      [BLOG_PAGE_TYPE.ENTERPRISE]: '',
      [BLOG_PAGE_TYPE.ENGINEERING]: engineeringClassnames.cardBorder,
    },
  },
});
